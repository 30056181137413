@tailwind base;
@tailwind components;
@tailwind utilities;

.EzPopover-mobile-time-picker {
  width: initial !important;
}

@layer base {
  body {
    font-feature-settings: 'kern', 'liga', 'pnum';
    font-family:
      Lato,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      'Helvetica Neue',
      Helvetica,
      Arial,
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol' !important;
    -webkit-font-smoothing: antialiased;
  }

  body > img {
    display: none;
  }

  /* Link styles are set to match EzLink in Recipe 19 */
  a {
    /* blueberry-400 */
    color: #3a64df;
  }

  a:hover {
    color: #345ac8;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }

  #liberty-webchat-iframe {
    display: none;
  }

  td {
    border-style: none;
  }

  @media screen(tablet) {
    #liberty-webchat-iframe {
      display: block;
    }
  }

  /*
    This was added to fix a scroll issue with the appsflyer banner.
    appsflyer renders an empty div as "space" for the banner itself,
    then renders the banner with position: fixed on the page. When the user
    scrolled, it caused conflicts with our mobile UI. This removes the fixed height
    from the initial placeholder div and ensures the banner is part of the dom hierarchy,
    allowing it to scroll up as the user scrolls down the app.

    PR: https://github.com/ezcater/store-next/pull/6476
  */
  div#appsflyer-container {
    > :not(.responsive-wrapper) {
      height: 0 !important;
    }

    .responsive-wrapper {
      position: relative !important;
    }
  }
}

@layer utilities {
  .left-nav-overflow {
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
  }

  .left-nav-overflow::-webkit-scrollbar {
    visibility: hidden;
    background: transparent;
    height: 8px;
    width: 8px;
  }

  .left-nav-overflow::-webkit-scrollbar-thumb {
    visibility: hidden;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    min-height: 40px;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .left-nav-overflow:hover {
    scrollbar-color: #dadce0 transparent;
  }

  .left-nav-overflow:hover::-webkit-scrollbar {
    scrollbar-color: #dadce0 transparent;
  }

  .left-nav-overflow:hover::-webkit-scrollbar-thumb {
    visibility: visible;
    background: #dadce0;
  }
}

@layer components {
  /* menu page styles */
  .section-header {
    @apply border-t-2 border-gray-200 bg-gray-100 px-3 py-2 text-sm font-bold uppercase tracking-wider text-black;
  }

  .menu-item-description::after {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    line-height: 1.5rem;
    max-height: 3em;
    content: '';
    width: 30%;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 1.5em;
  }

  /* Hide the default triangle in Safari. https://developer.mozilla.org/en-US/docs/Web/HTML/Element/summary#default_style */
  .disclosure-details > summary::-webkit-details-marker {
    display: none;
  }

  /* ezrewards page background gradient */
  div:has(.ez-rewards-bg-img) {
    background-image: linear-gradient(225deg, #f2ffe9 3%, #ffffff 10%);

    @media screen(tablet) {
      background-image: linear-gradient(210deg, #f2ffe9 10%, #ffffff 15%);
    }

    @media screen(desktop) {
      background-image: linear-gradient(210deg, #f2ffe9 10%, #ffffff 25%);
    }
  }
}

/* react-datepicker - this is outside of a @layer directive so it doesn't get purged */
.react-datepicker.event-bar-react-datepicker {
  @apply block rounded-none border-0 font-lato;
}

.event-bar-react-datepicker .react-datepicker__triangle {
  @apply hidden;
}

.event-bar-react-datepicker .react-datepicker__month-container {
  @apply float-none;
}

.event-bar-react-datepicker .react-datepicker__month {
  @apply mx-0 my-1.5;
}

.event-bar-react-datepicker .react-datepicker__current-month {
  @apply mb-3;
}

.event-bar-react-datepicker .react-datepicker__header {
  @apply rounded-none border-0 bg-white;
}

.event-bar-react-datepicker .react-datepicker__week,
.event-bar-react-datepicker .react-datepicker__day-names {
  @apply flex;
}

.event-bar-react-datepicker .react-datepicker__week {
  @apply -mb-px;
}

.event-bar-react-datepicker .react-datepicker__week:last-of-type {
  @apply mb-0;
}

.event-bar-react-datepicker .react-datepicker__day,
.event-bar-react-datepicker .react-datepicker__day-name {
  @apply flex-1 basis-0;
}

.event-bar-react-datepicker .react-datepicker__day {
  @apply m-0 -ml-px w-auto border border-gray-300;
}

.event-bar-react-datepicker .react-datepicker__day:first-of-type {
  @apply ml-0;
}

.event-bar-react-datepicker .react-datepicker__day:hover {
  @apply rounded-none;
}

.event-bar-react-datepicker .react-datepicker__day-name {
  @apply m-0 uppercase;
}

.event-bar-react-datepicker .react-datepicker__day--today {
  @apply font-black text-ezo-secondary;
}

.event-bar-react-datepicker .react-datepicker__day--selected {
  @apply rounded-none bg-ezo-secondary font-black text-white;
}

.event-bar-react-datepicker .react-datepicker__day--selected:hover {
  @apply bg-ezo-secondary;
}

.event-bar-react-datepicker .react-datepicker__day--keyboard-selected {
  @apply rounded-none bg-[#f0f0f0] font-normal text-black;
}

.event-bar-react-datepicker .react-datepicker__day--keyboard-selected.react-datepicker__day--today {
  @apply font-black text-ezo-secondary;
}
